<!--
    Copyright Compunetix Incorporated 2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<top-bar></top-bar>
<nav-bar></nav-bar>
<main-container>
<div class="role-management-page p-2">
  <button
    type="button"
    (click)="createRole()"
    class="btn btn-primary text-uppercase"
  >
    Create Role
  </button>
  <button
  type="button"
  (click)="cloneRole()"
  class="btn btn-primary text-uppercase"
  [disabled]="!selectedRole"
>
  Clone Role
</button>
  <button
    type="button"
    (click)="editRole()"
    class="btn btn-primary text-uppercase"
    [disabled]="!selectedRole"
  >
    Edit Role
  </button>
  <button
    type="button"
    (click)="deleteRole()"
    class="btn btn-primary text-uppercase"
    [disabled]="!selectedRole"
  >
    Delete Role
  </button>
  <div
    class="modal fade role-modal"
    id="role-modal"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-body">
          <role-details
            *ngIf="selectedRole"
            (onSubmit)="formSubmitted($event)"
            (onCancel)="formCancelled()"
            [role]="selectedRole"
          ></role-details>
        </div>
      </div>
    </div>
  </div>
  <H3>Roles</H3>
  <ngx-datatable
    #roleTable
    class='material striped expandable'
    [rows]='rows'
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="50"
    [scrollbarH]="true"
    [sortType]="'multi'"
    [selected]="selected"
    [selectionType]="'single'"
    [limit]="10"
    (select)='onSelect($event)'
  >
    <!-- Column Templates -->
    <ngx-datatable-column
      name="Role Name"
      prop="name"
      headerClass="table-column-header"
    ></ngx-datatable-column>
  </ngx-datatable>
</div>
</main-container>
<footer></footer>