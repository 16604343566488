/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, ViewChild } from "@angular/core";
import { Companion, Cookie, IRTCClient, IUser, IVideoFilter } from "companion";
import { LocalizationService } from "./localization/localization.service";
import { UserManagementService } from "./user-management/user-management.service";
import { Router } from "@angular/router";
import { Dispatcher, ActionType } from "./shared/services/dispatcher";
import { GroupManagementService } from "./group-management/group-management.service";

@Component({
  selector: "app",
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  appLoaded: boolean = false;
  rtcClient: IRTCClient = Companion.getRTCClient();
  get videoFilter(): IVideoFilter {
    return this.rtcClient.videoFilter;
  }
  constructor(
    public localizationService: LocalizationService,
    private userManagementService: UserManagementService,
    private groupManagementService: GroupManagementService,
    private router: Router
  ) {

    const max = 100;
    const min = 1;
    const random = Math.floor(Math.random() * (max - min + 1)) + min;
    Cookie.setCookie("CompanionNumber", random.toString(), 1, "/", null);
    Companion.init();
    this.init();
  }

  init() {
    this.userManagementService
    .isAuthenticated()
    .then((result: boolean) => {
      if (result) {
        return this.userManagementService.getMyPermissions()
        .then((permissions: any) => {
          return this.userManagementService.getUserWithRolesAndGroupsById(Companion.getUserService().currentUser["_id"])
          .catch((error: Error) => Promise.reject(error));
        })
        .then((user: any) => {
          this.appLoaded = true;
          return Promise.resolve();
        })
        .catch((error: Error) => Promise.reject(error));
      } else {
        Companion.getUserService().currentUser.permissions = {};
        this.appLoaded = true;
        return Promise.resolve();
      }
    })
    .catch((err: Error) => {
      console.error(err);
    });

    this.userManagementService.checkIfDashboardIsEnabled();

    Dispatcher.register(ActionType.OpenDefaultScreen, this.OpenDefaultScreen.bind(this));
    
    Dispatcher.register(ActionType.OpenThemeManager, this.open.bind(this, ["themes"]));
    Dispatcher.register(ActionType.OpenSystemConfiguration, this.open.bind(this, ["config"]));
    Dispatcher.register(ActionType.OpenUserManagement, this.open.bind(this, ["users"]));
    Dispatcher.register(ActionType.OpenRoleManagement, this.open.bind(this, ["roles"]));
    Dispatcher.register(ActionType.OpenGroupManagement, this.open.bind(this, ["groups"]));
    Dispatcher.register(ActionType.OpenDashboard, this.open.bind(this, ["dashboard", "overview"]));
    Dispatcher.register(ActionType.OpenMyAccount, this.open.bind(this, ["my-account"]));
    Dispatcher.register(ActionType.OpenVCC, this.OpenVCC.bind(this));
    Dispatcher.register(ActionType.OpenVCCList, this.open.bind(this, ["vcc", "list"]));
    Dispatcher.register(ActionType.EnterFullScreen, this.enterFullScreen.bind(this));
    Dispatcher.register(ActionType.ExitFullScreen, this.exitFullScreen.bind(this));
    this.groupManagementService.checkLicense();
  }
  /**
   * enter full screen mode
   */
  enterFullScreen() {
    try {
      var elem: any = document.body;
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
        .catch(() => console.log("Unable to enter fullscreen"));
      }
      if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen()
        .catch(() => console.log("Unable to enter fullscreen"));
      }
      if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen()
        .catch(() => console.log("Unable to enter fullscreen"));
      }
    } catch (e) {
      // nothing needed here
    }
  }
  /**
   * exit full screen mode
   */
  exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    }
    if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    }
  }

  /**
   * open page
   */
  open(routes: string[]) {
    if (this.localizationService.myLocalizationData.style != null) {
      var stylePath = this.localizationService.myLocalizationData.style.replace(/^default$/i, "");
      this.router.navigate(_.concat("/" + stylePath, routes));
    } else {
      setTimeout(this.open.bind(this, routes), 500);
    }
  }

  /**
   * open conference page
   */
  OpenConferenceWindow(payload: any): void {
    let url = _.join(
      [
        window.location.protocol +
          window.location.port +
          "//" +
          window.location.hostname +
          (payload.theme == null || payload.theme.toLowerCase() === "default" ? "" : "/" + payload.theme),
        "c",
        payload.isHost ? "h" : "j",
        payload.passcode,
      ],
      "/"
    );
    let videoScreen = window.open(
      url,
      "videoScreen-" + payload.passcode,
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,top=0,right=0"
    );
    videoScreen.moveTo(0, 0);
    videoScreen.resizeTo(screen.width, screen.height);
    if (payload && payload.conference) {
      payload.conference.isOpened = true;
    }
    this.listenOnConferenceWindowClose(videoScreen, () => {
      if (payload && payload.conference) {
        payload.conference.isOpened = false;
      }
    });
  }

  /**
   * open conference page
   */
  OpenConferencePage(payload: any): void {
    this.open(["c", payload.isHost ? "h" : "j", payload.passcode]);
  }

  listenOnConferenceWindowClose(videoScreen: Window, onCloseCallback: () => void) {
    let checkWindowCloseTimeout = setTimeout(() => {
      clearTimeout(checkWindowCloseTimeout);
      if (videoScreen.closed) {
        onCloseCallback();
      } else {
        this.listenOnConferenceWindowClose(videoScreen, onCloseCallback);
      }
    }, 0.1 * 1000);
  }

  /**
   * open default page
   */
  OpenDefaultScreen(payload: any): void {
    let currentUser: IUser = Companion.getUserService().currentUser;

    if (!currentUser.isAuthenticated) {
      this.open(["portal"]);
    } else {
      this.localizationService.myLocalizationData = {};
      this.router.navigate(["/", "my-account"]);
    }
  }

  /**
   * open VCC page
   */
  OpenVCC(payload: any): void {
    if (payload && payload.theme) {
      this.router.navigate(["/", payload.theme, "operators"]);
    } else {
      this.open(["operators"]);
    }
  }
}
