/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Component, ViewChild, OnInit } from "@angular/core";
import { RoleManagementService } from "./role-management.service";
import { IRole, Role, Companion, AlertLevel } from "companion";
import { RoleDetailsComponent } from "./role-details.component";
import { AlertService } from "../alert/alert.service";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { NavBarMenuItemKey } from "../layout/nav-bar/nav-bar.service";

@Component({
  selector: "role-management",
  templateUrl: "./role-management.template.html"
})
export class RoleManagementComponent implements OnInit {
  // the role array
  rows: any[] = [];
  selected: any = [];
  selectedRole: IRole;

  @ViewChild(RoleDetailsComponent)
  RoleDetailsComponent: RoleDetailsComponent;

  /**
   * Role profile service
   */
  private roleManagementService: RoleManagementService;

  constructor(roleManagementService: RoleManagementService, private alertService: AlertService) {
    this.roleManagementService = roleManagementService;
  }

  ngOnInit() {
    if (
      !Companion.getUserService().currentUser.isAuthenticated ||
      !(Companion.getUserService().currentUser.permissions.createRoles > 0)
    ) {
      Dispatcher.dispatch(ActionType.OpenDefaultScreen);
      return;
    }
    Dispatcher.dispatch(ActionType.LoadHostUserNavBar, { active: NavBarMenuItemKey.RoleManagement });
    Dispatcher.dispatch(ActionType.LoadTopBarMenu);
    this.getRoles();
  }

  updateRows(roles: IRole[]) {
    this.rows = roles;
  }

  onSelect($event: Event) {
    this.selectedRole = this.selected[0];
  }

  /**
   * Create role form
   */
  createRole() {
    this.selectedRole = new Role("");
    // show the modal window
    jQuery("#role-modal").modal("show");
  }

  /**
   * Delete a role
   */
  deleteRole() {
    if (this.selectedRole) {
      // confirm that we want to delete the role
      AlertService.createAlertWithButtons("Are you sure you want to delete " + this.selectedRole.name + "?", {
        confirm: {
          label: "Yes",
          className: "btn-success"
        },
        cancel: {
          label: "No",
          className: "btn-default"
        }
      }).then((result: boolean) => {
        if (result) {
          this.roleManagementService.deleteRole(this.selectedRole)
          .then((data: any) => {
            if (data.success) {
              // update the roles after a delete
              this.getRoles();
            } else {
              this.alertService.createAlert("DELETE_ROLE_FAILED", data.reason, AlertLevel.warning);
            }
          }).catch((error: any) => {
            console.log(`Failed to deleteRole: ${JSON.stringify(error)}`);
          });
        }
      });
    }
  }

  /**
   * Clone a role
   */
  cloneRole() {
    // get the selected role
    if (this.selectedRole) {
      if (this.RoleDetailsComponent) {
        this.RoleDetailsComponent.updateView(this.selectedRole, false);
      }

      // update the form info with the selected role

      // open the modal in edit mode
      jQuery("#role-modal").modal("show");
    }
  }

  /**
   * edit a role
   */
  editRole() {
    // get the selected role
    if (this.selectedRole) {
      if (this.RoleDetailsComponent) {
        this.RoleDetailsComponent.updateView(this.selectedRole, true);
      }

      // update the form info with the selected role

      // open the modal in edit mode
      jQuery("#role-modal").modal("show");
    }
  }

  formSubmitted($event: IRole) {
    // hide the modal window
    jQuery("#role-modal").modal("hide");

    this.selectedRole = null;
    // update the roles after a create
    this.getRoles();
  }

  formCancelled($event: any) {
    // hide the modal window
    jQuery("#role-modal").modal("hide");
    this.selectedRole = null;
    this.getRoles();
  }

  getRoles() {
    // get the roles
    this.roleManagementService.getMyAssignableRoles().then((data: IRole[]) => {
      this.updateRows(data);
    }).catch((error: any) => {
      console.log(`Failed to getMyAssignableRoles: ${JSON.stringify(error)}`);
    });

    this.selectedRole = null;
  }
}
