/**
 * Copyright Compunetix Incorporated 2017-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { PageNotFoundComponent } from "./page-not-found.component";
import { ConfigComponent } from "./config/config.component";
import { GroupManagementComponent } from "./group-management/group-management.component";
import { RoleManagementComponent } from "./role-management/role-management.component";
import { UserManagementPageComponent } from "./user-management/user-management.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";
import { BrowserNotSupportedComponent } from "./browser-not-supported.component";
import { HelperComponent } from "./helper/helper.component";
import { LoginComponent } from "./login/login.component";
import { InvalidGuestEntryComponent } from "./invalid-guest-entry.component";

const routes: Routes = [

  { path: ":style/operators", 
    loadChildren:() => import('./call-center/call-center-agent.module').then(x=>x.CallCenterAgentModule)
  },
  { path: ":style/operators/:language", 
    loadChildren:() => import('./call-center/call-center-agent.module').then(x=>x.CallCenterAgentModule)
  },
  { path: ":style/operators/:language/:location", 
    loadChildren:() => import('./call-center/call-center-guest.module').then(x=>x.CallCenterGuestModule) 
  },

  { path: ":style/:mode/operators", 
    loadChildren:() => import('./call-center/call-center-agent.module').then(x=>x.CallCenterAgentModule)
  },
  { path: ":style/:mode/operators/:language", 
    loadChildren:() => import('./call-center/call-center-agent.module').then(x=>x.CallCenterAgentModule)
  },
  { path: ":style/:mode/operators/:language/:location", 
    loadChildren:() => import('./call-center/call-center-guest.module').then(x=>x.CallCenterGuestModule)
  },

  { path :"guests",
    loadChildren:() => import('./call-center/call-center-guest.module').then(x=>x.CallCenterGuestModule)},
  { path :":style/guests",
    loadChildren:() => import('./call-center/call-center-guest.module').then(x=>x.CallCenterGuestModule)},

  { path :"agents",
    loadChildren:() => import('./call-center/call-center-agent.module').then(x=>x.CallCenterAgentModule)},
  { path :":style/agents",
    loadChildren:() => import('./call-center/call-center-agent.module').then(x=>x.CallCenterAgentModule)},

  /**
   * VCC call center.
   */
  { path: "vcc/:displayMode", 
    loadChildren:() => import('./call-center/call-center-list.module').then(x=>x.CallCenterListModule)},
  { path: ":style/vcc/:displayMode", 
    loadChildren:() => import('./call-center/call-center-list.module').then(x=>x.CallCenterListModule)},

  /**
   * Root?
   */
  { path: "", component: LoginComponent },
  { path: "portal", component: LoginComponent },
  { path: "resetPassword/:resetToken", component: PasswordResetComponent },

  /**
   * ADMIN MODULE...
   */
  { path: "themes", loadChildren:() => import('./flavor-factory/flavor-factory.module').then(x=>x.FlavorFactoryModule) },
  { path: ":style/themes", loadChildren:() => import('./flavor-factory/flavor-factory.module').then(x=>x.FlavorFactoryModule)},
  { path: "config", component: ConfigComponent },
  { path: "users", component: UserManagementPageComponent },
  { path: "roles", component: RoleManagementComponent },
  { path: "groups", component: GroupManagementComponent },
  { path: "my-account", component: UserProfileComponent },

  { path: ":style/dashboard",  loadChildren:() => import('./dashboard/dashboard.module').then(x=>x.DashboardModule)},
  { path: "license", loadChildren:() => import('./license/license.module').then(x=>x.LicenseModule)},

  { path: ":style/portal", component: LoginComponent },
  { path: ":style/config", component: ConfigComponent },
  { path: ":style/users", component: UserManagementPageComponent },
  { path: ":style/roles", component: RoleManagementComponent },
  { path: ":style/groups", component: GroupManagementComponent },
  { path: ":style/my-account", component: UserProfileComponent },
  { path: ":style/resetPassword/:resetToken", component: PasswordResetComponent },
  { path: "helper", component: HelperComponent },
  { path: "not-found", component: PageNotFoundComponent },
  { path: "not-supported", component: BrowserNotSupportedComponent },
  { path: "invalid-guest-entry", component: InvalidGuestEntryComponent },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
