/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender, lcheng
 */
import { Component, EventEmitter, Output, Input } from "@angular/core";
import { RestService } from "../shared/services/rest.service";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { AlertLevel, Alert, IPasswordPolicy, PasswordChecker } from "companion";

@Component({
  selector: "password-reset-form",
  templateUrl: "./password-reset-form.component.html"
})
export class PasswordResetFormComponent {
  /**
   * the reset event trigger, true if a reset was trigger, false if no reset was triggered.
   */
  @Output("resetOccured")
  resetEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Whether or not the reset token we have been given is valid
   */
  validToken: boolean;

  /**
   * The password
   */
  password: string;

  /**
   * Confirm the password
   */
  confirmPassword: string;

  /**
   * The request token
   */
  @Input("resetToken")
  resetToken: string;

  /**
   * The password policy
   */
  @Input("passwordPolicy")
  passwordPolicy: IPasswordPolicy;

  /**
   * Report if there is a problem with the password adhering
   * to the policy
   */
  passwordProblem: string;

  /**
   * flag if form submitted
   */
  submitted: boolean;

  /**
   * component constructor
   */
  constructor(private restService: RestService) {
    // nothing needed here
  }

  /**
   * Reset submitted
   */
  formSubmitted() {
    this.submitted = true;
    if (this.confirmPassword !== this.password) {
      Dispatcher.dispatch(ActionType.Alert, {
        alert: new Alert("CONFIRM_PASSWORD_NOT_MATCH", "Confirm Password does not match", AlertLevel.warning)
      });
      return;
    }

    // send the reset message with the token and the new password.
    this.restService
      .post("/resetPasswordLink", {
        password: this.password,
        resetToken: this.resetToken
      })
      .subscribe(
        (data: any) => {
          if (data.success === true) {
            Dispatcher.dispatch(ActionType.Alert, {
              alert: new Alert(
                "PASSWORD_RESET_SUCCESS",
                "Password is successfully reset. Please log in with your new password.",
                AlertLevel.success
              )
            });
            this.resetEmitter.emit(true);
          } else {
            Dispatcher.dispatch(ActionType.Alert, {
              alert: new Alert("PASSWORD_RESET_FAIL", data.message, AlertLevel.warning)
            });
            this.resetEmitter.emit(false);
          }
        },
        (error: any) => {
          Dispatcher.dispatch(ActionType.Alert, {
            alert: new Alert("PASSWORD_RESET_REQUEST_FAIL", error, AlertLevel.warning)
          });
          this.resetEmitter.emit(false);
        }
      );

    return true;
  }

  /**
   * reset cancelled
   * @return false to avoid resubmitting the form
   */
  resetCancelled() {
    // the user cancelled the form
    this.resetEmitter.emit(false);
    return false;
  }

  /**
   * Validate password against the policy
   */
  passwordChange($event) {
    if (this.passwordPolicy) {
      this.passwordProblem = PasswordChecker.CheckPasswordAgainstPolicy(this.passwordPolicy, this.password);
    } else {
      this.passwordProblem = null;
    }
  }
}
