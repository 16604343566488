<!--
    Copyright Compunetix Incorporated 2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<top-bar></top-bar>
<nav-bar></nav-bar>
<main-container>
  <div class="user-management-page p-2">
    <h3>Users</h3>
    <div class="row mb-1">
      <div class="col-md-4">
        <button type="button" (click)="createUser()" class="btn btn-primary">
          CREATE_USER
        </button>
        <button type="button" (click)="editUser()" class="btn btn-primary" [disabled]="!hasPermissionToEdit">
          EDIT_USER
        </button>
        <button type="button" (click)="deleteUser()" class="btn btn-primary" [disabled]="!hasPermissionToDelete">
          DELETE_USER
        </button>
      </div>
      <div class="col-md-4" style="font-weight: 500">
          Per Page: 
          <button type="button" (click)="setLimit(10)" 
            [ngClass]="{'btn-primary': limit === 10, 'btn-secondary': limit !== 10}" class="btn">
            10
          </button>
          <button type="button" (click)="setLimit(25)"
            [ngClass]="{'btn-primary': limit === 25, 'btn-secondary': limit !== 25}" class="btn">
            25
          </button>
          <button type="button" (click)="setLimit(50)"
            [ngClass]="{'btn-primary': limit === 50, 'btn-secondary': limit !== 50}" class="btn">
            50
          </button>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Type to filter the username column..."
          (keyup)="updateFilter($event)" />
      </div>
    </div>
    <dialog-container-modal [id]="'user-form'" [enableKeyboardControl]="false">
      <user-form (changeOccured)="formSubmitted($event)" [style]="{width: '73vw', display: 'inherit'}"></user-form>
    </dialog-container-modal>
    <ngx-datatable #userTable class='material striped expandable' [rows]='rows' [columnMode]="'force'"
      [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [scrollbarH]="true" [sortType]="'multi'"
      [selected]="selected" [selectionType]="'single'" [limit]="limit" (select)='onSelect($event)'>
      <ngx-datatable-column name="Email Address or Username" prop="username"
        headerClass="table-column-header"></ngx-datatable-column>
      <ngx-datatable-column name="Real Name" prop="name" headerClass="table-column-header"></ngx-datatable-column>
      <ngx-datatable-column name="Roles" prop="roleNames" headerClass="table-column-header"></ngx-datatable-column>
      <ngx-datatable-column name="Groups" prop="groupNames" headerClass="table-column-header"></ngx-datatable-column>
      <ngx-datatable-column name="Status" prop="accountStatus" headerClass="table-column-header"></ngx-datatable-column>
      <ngx-datatable-column name="Last Active" prop="lastActive" [comparator]="lastActiveSort"
        headerClass="table-column-header"></ngx-datatable-column>
      <ngx-datatable-column name="Locked Out (mins)" prop="lockoutDisplay" [comparator]="numericSort"
        headerClass="table-column-header"></ngx-datatable-column>
      <ngx-datatable-column name="Actions" sortable="false" prop="_id">
        <ng-template let-user="row" ngx-datatable-cell-template>
          <button class="btn-sm btn-warning" [disabled]="!checkPermissionToEdit(user)"
            (click)="forcePasswordReset(user)">
            Force Reset Password
          </button>
          <button type="button" (click)="enableUser(user)" class="btn-sm btn-success" *ngIf="user.isDisabled"
            [disabled]="!checkPermissionToEdit(user)">
            Enable
          </button>
          <button type="button" (click)="disableUser(user)" class="btn-sm btn-danger" *ngIf="!user.isDisabled"
            [disabled]="!checkPermissionToEdit(user)">
            Disable
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</main-container>
<footer></footer>