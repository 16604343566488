/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component } from "@angular/core";
import { Companion, ConferenceService } from "companion";

@Component({
  selector: "page-not-found",
  template: `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Page Not Found</title>
      </head>

      <body>
        <h1>Page Not Found</h1>
        <style type="text/css">
          h1 {
            text-align: center;
            padding-top: 25%;
            margin: 0px;
            position: fixed;
            bottom: 0px;
            top: 0px;
            left: 0px;
            right: 0px;
            color: #fff;
            background: linear-gradient(0deg, #bdddf4 0%, #3f4163 80%, #231f20 100%);
          }
        </style>
      </body>
    </html>
  `
})
export class PageNotFoundComponent {
  // Stop talking to the server.
  ngOnInit() {
    Companion.getRTCService().disconnect();
    ConferenceService.getSharedInstance().preventReload = false;
  }
}
