<!--
    Copyright Compunetix Incorporated 2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<top-bar></top-bar>
<nav-bar></nav-bar>
<main-container>
  <div class="container">
    <div
      class="row profile"
      *ngIf="user"
    >
      <div class="col-md-3">
        <div class="profile-sidebar">
          <!-- SIDEBAR USERPIC -->
          <div class="profile-userpic" (mouseenter)="displayUploadAvatar()" (mouseleave)="hideUploadAvatar()">
            <img
              [attr.src]="user.avatar || '/images/avatar.png'"
              crossorigin="anonymous"
              class="img-fluid"
              alt=""
            >
            <div class="avatar-uploader">
            <input
              type="file"
              #avatarUploader
              (change)="uploadAvatar($event)"
              placeholder="Upload New Avatar"
              accept=".jpg,.jpeg,.png,.gif"
              hidden
            >
            <button class="btn btn-secondary avatar-upload-button" [hidden]="!uploadAvatarShown" (click)="avatarUploader.click()">Upload</button>
            </div>
          </div>
          <!-- END SIDEBAR USERPIC -->
          <!-- SIDEBAR USER TITLE -->
          <div class="profile-usertitle">
            <div class="profile-usertitle-name">
              {{user.name}}
            </div>
            <div class="profile-usertitle-job">
              {{user.roles[0] ? user.roles[0].name : ""}} | {{user.groups[0] ? user.groups[0].name : ""}}
            </div>
          </div>
          <!-- END SIDEBAR USER TITLE -->
          <!-- SIDEBAR BUTTONS -->
          <div class="profile-userbuttons">
            <button
              type="button"
              class="btn btn-danger btn-sm"
              (click)="openResetPassword()"
            >Reset Password</button>
          </div>
          <!-- END SIDEBAR BUTTONS -->
          <!-- SIDEBAR MENU -->
          <div class="mt-2">
            <ul class="list-group">
              <li
                class="list-group-item py-0"
                [hidden]="!user.email"
              >
                <a class="btn" href="mailto:{{user.email}}"> <i class="fas fa-envelope"></i> {{user.email}}</a>
              </li>
              <li
                class="list-group-item py-0"
                [hidden]="!user.phone"
              >
                <a class="btn" href="tel:{{user.phone}}"> <i class="fas fa-phone"></i> {{user.phone}}</a>
              </li>
            </ul>
          </div>
          <!-- END MENU -->
        </div>
      </div>
      <div class="col-md-9">
        <div
          class="profile-content"
          [ngSwitch]="mode"
        >
          <user-form *ngSwitchCase="'profile'"  [style]="{width: '73vw', display: 'contents'}"></user-form>
        </div>
      </div>
    </div>
  </div>
</main-container>
<footer></footer>
