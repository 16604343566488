/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SharedModule } from "../shared/shared.module";
import { LayoutModule } from "./../layout/layout.module";

import { RoleManagementComponent } from "./role-management.component";
import { RoleManagementService } from "./role-management.service";
import { RoleDetailsComponent } from "./role-details.component";

@NgModule({
  imports: [BrowserModule, FormsModule, NgxDatatableModule, SharedModule, LayoutModule],
  declarations: [RoleManagementComponent, RoleDetailsComponent],
  providers: [RoleManagementService],
  exports: [RoleManagementComponent]
})
export class RoleManagementModule {}
